.ideology {
  width: 100%;
  height: 1397px;

  z-index: 1;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 1.2s;
  &-body {
    width: 980px;
    height: 1136px;
    .map {
      width: 980px;
      height: 485px;
    }
    text-align: center;
    &-kanye {
      width: 980px;
      height: 544px;
      margin-top: 0px;
      margin-bottom: 56px;
    }
    .quote-eng {
      font-size: 30px;
      line-height: 1.2em;
      font-family: brandon-grot-w01-light, brandon-grot-w05-light, sans-serif;
      margin-bottom: 20px;
    }
    .quote-kor {
      line-height: 1.2em;

      font-size: 17px;
      color: #939191;
      font-family: brandon-grot-w01-light, brandon-grot-w05-light, sans-serif;
      margin-bottom: 61px;
    }
  }
}
.ideology::before {
  content: '';
  background-image: url('https://static.wixstatic.com/media/1e6afe_35737db131a44dc2b51dd1a876deeddc~mv2.png/v1/fill/w_1920,h_1903,al_c,q_95,usm_0.66_1.00_0.01,enc_auto/1e6afe_35737db131a44dc2b51dd1a876deeddc~mv2.png');
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.025;
  z-index: -1;
}
