.header {
  width: 100%;
  height: 261px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
  .youtube {
    width: 196px;
    height: 261px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-right: 45px;
    a {
      margin-top: 25px;
      height: 143px;
      margin-bottom: 5px;
    }
    &-square {
      width: 196px;
      height: 31px;
      font-family: wfont_6f1958_8db03b9c604041e5abe2a390aaf24041,
        wf_8db03b9c604041e5abe2a390a, orig_210_didimgothicotf_030;
      font-size: 20px;
      font-weight: 400;
      color: #414141;
      letter-spacing: 0.15em;
    }
    &-since {
      width: 196px;
      height: 21px;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.15em;
      margin-bottom: 36px;
      color: #414141;
    }
  }

  &-menu {
    transition: ease;
    margin-left: 30px;
    width: 151px;
    height: 40px;
    text-align: center;
    line-height: 2;
    color: #282626;

    font-style: normal;
    letter-spacing: 0em;
    font-size: 20px;

    font-family: cinzel;
    font-weight: 300;

    &-clicked {
      @extend .header-menu;
      background-color: #cadce9;
    }
  }
  &-menu:hover {
    @extend .header-menu;
    letter-spacing: 0.1em;
    cursor: pointer;
    text-decoration: underline;
    transition: 0.25s;
  }
  &-work {
    width: 181px;
    height: 280px;
    margin-top: 240px;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    z-index: 10;
    &-tab {
      font-size: 20px;
      width: 181px;
      height: 40px;
      text-align: center;
      line-height: 2;
      border-style: solid;
      border-bottom-width: 1px;
      color: #767676;
    }
    &-tab:hover {
      @extend .header-work-tab;
      cursor: pointer;
      background-color: #cadce9;
      transition: 0.7s;
    }
  }
}
